import React from "react";
import { useSelector } from "react-redux";
import Pagination from "../../../../SharedComponents/Pagination/Pagination";
import Table from "components/Table";
import BotDetailsData from "../BotDetails/BotDetailsData";
import { utcTimeStringToLocalTimeString } from "SharedComponents/DateManager"
import { useDispatch } from "react-redux";
import TrafficMessage from "../../../../SharedComponents/TrafficMessage/TrafficMessage";
import { botClassificationName, riskLevels, colors } from "components/AFP/Constants";

const BotDetails = () => {
	const dispatch = useDispatch();
	let itemsPerPage = 5;
	const [page, setPage] = React.useState(1);
	const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
	const botDetails = useSelector(state => state?.botDetails.data);

	const headers = [
		{ headerName: "Last Seen", field: "last_seen", valueFormatter: (val) => utcTimeStringToLocalTimeString(val, "", "HH:mm:ss"), title: (val) => utcTimeStringToLocalTimeString(val, "", "HH:mm:ss") },
		{ headerName: "IP Address", field: "ip" },
		{ headerName: "Country", field: "country", title: (val) => (val !== 'unknown' && val !== "null") ? regionNamesInEnglish.of(val) : val },
		{ headerName: "Referrer", field: "referrer", valueFormatter: (val) => regexForReferrer(val) },
		{ headerName: "OS", field: "os" },
		{ headerName: "Landing Page", field: "landing_page", valueFormatter: (val) => decodeURI(val) },
		{ headerName: "Page Loads", field: "page_loads" },
		{ headerName: "Bot Type", field: "bot_classification_id", valueFormatter: (val) => botClassificationName[val], title: (val) => botClassificationName[val] },
		{ headerName: "Bot Risk", field: "bot_risk_type_id", valueFormatter: (val) => botRisk(val), title: (val) => riskLevels[val] },
	];


	const regexForReferrer = (val) => {
		const result = val.replace(/https?:\/\/(www\.)?|www\./, '');
		return result;
	};

	const botRisk = (val) => {
		return (
			<div className="bot_risk_cell" style={{ backgroundColor: colors[val] }} >
				{riskLevels[val]}
			</div>
		)
	};

	React.useEffect(() => {
		BotDetailsData({ timeRange: botDetails.timeRange }, dispatch, page);
	}, [page]);

	return (
		<>
			<div className="bot-details-section">
				{botDetails.items.length !== 0 ?
					<Table
						headers={headers}
						data={botDetails.items}
					/> :
					<TrafficMessage
						message="There are no bots for this time Period."
					/>
				}
				{botDetails.paging.total > 5 &&
					<Pagination
						paging={botDetails.paging}
						currentPage={page}
						setCurrentPage={setPage}
						pageLimit={itemsPerPage}
					/>
				}
			</div>
		</>
	)
};


export default BotDetails;