import React from "react";
import DateDropDown from "../../../SharedComponents/DateDropDown/DateDropDown";
import BlockedSessionsGeographyPanel from "../BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyPanel";
import BlockedBotsCo2 from "../BotActivityPanels/BlockedBotsCO2/BlockedBotsCo2";
import { store } from "../../../Redux/store";
import { useSelector, useDispatch } from "react-redux";
import FinishedSessionsPanel from "../BotActivityPanels/FinishedSessionsPanel/FinishedSessionsPanel";
import BlockedSessionsGeographyPanelData from "../BotActivityPanels/BlockedSessionsGeographyPanel/BlockedSessionsGeographyPanelController";
import BlockedBotsCo2Controller from "../BotActivityPanels/BlockedBotsCO2/BlockedBotsCo2Controller";
import FinishedSessionsPanelData from "../BotActivityPanels/FinishedSessionsPanel/FinishedSessionsPanelController";
import BotRiskForBotActivity from "../BotActivityPanels/BotRiskForBotActivity/BotRiskForBotActivity";
import BotRiskControllerforBotActivity from "../BotActivityPanels/BotRiskForBotActivity/BotRiskForBotActivityContoller";
import "./BotActivityTile.scss";
import { Link } from "react-router-dom";

const ProtectionTile = (props) => {
	const dispatch = useDispatch();
	const finishedSessionsData = useSelector(state => state?.finishedSessions?.data || []);
	const graphsHeading = store.getState()?.websiteDetails?.data?.websiteDetails.status === "protect" ? "Blocked" : "Detected";
	const [graph, setGraph] = React.useState(null);
	const [value, setValue] = React.useState({
		graph: null,
		timeRange: null
	});
	const dateDropDownValues = ['Prev 7 Days', 'Prev 14 Days', 'Prev 28 Days', 'This Month', 'Last Month'];
	const graphSelect = () => {

		switch (props.graph) {
			case 'Bot Threat':
				return <BotRiskForBotActivity loading={props.loading} setLoading= {props.setLoading} timeRange={value.timeRange}/>
			case `Good Bots and CO2`:
				return <BlockedBotsCo2 loading={props.loading} setLoading= {props.setLoading}/>
			case "Finished Sessions":
				return <FinishedSessionsPanel loading={props.loading} setLoading= {props.setLoading} />
			case 'Bot Sources':
				return <BlockedSessionsGeographyPanel loading={props.loading} setLoading= {props.setLoading} />
				
			default:

				return <></>
		};
	};

	React.useEffect(() => {
		if (value.graph !== "Finished Sessions") {
			setValue({
				graph: "Finished Sessions",
				timeRange: finishedSessionsData?.timeRange??'Prev 7 Days'
			});
		} else {
			if (value.timeRange !== finishedSessionsData?.timeRange) {
				props.setLoading(true);
			
				Promise.all([
					BlockedSessionsGeographyPanelData({ timeRange: value?.timeRange }, dispatch),
					BlockedBotsCo2Controller({ timeRange: value?.timeRange }, dispatch),
					FinishedSessionsPanelData({ timeRange: value?.timeRange }, dispatch),
					BotRiskControllerforBotActivity({ timeRange: value?.timeRange }, dispatch)
				]).then(() => {
					props.setLoading(false);
				}).catch((e) => {
					props.setLoading(false);
				});
			}
			
		};
	}, [value]);

	React.useEffect(() => {
		setGraph(graphSelect());
	}, [value, props.loading]);


	return (
		<div
			className="graph-tile"
			style={{ width: '95%' }}
		>
			<div className="graph-tile-header">
			<div className="header-details">
						<h2>{props.graph}</h2>
						{props.graph === "Bot Threat" && <Link to={`/help/bot-types`}>what is this?</Link>}
					</div>
				<div className="button-box">
					<div className="date-dropdown-container">
						{props.graph === "Bot Threat" &&
							<DateDropDown setValue={setValue} value={value} dateDropDownValues={dateDropDownValues} />
						}
					</div>

				</div>
			</div>
			<div className="graph-body">
				{graph}
			</div>
		</div>
	)
};

export default ProtectionTile;
