import React from "react";
import TrafficMessage from "../../../../../SharedComponents/TrafficMessage/TrafficMessage";
import { useSelector } from "react-redux";
import Table from "components/Table";

const ActivePages = (props) => {
	const websiteStatus = useSelector(state => state.websiteDetails.data.websiteDetails.status);
	const botsString = websiteStatus === "protect" ? "Bot Landings Stopped" : "Bots Currently Active";

	const ActivePagesData = () => {
		const data = props.activePagesData.filter((ele) => ele.id !== "Other");
		const headers = [
			{ headerName: "Page", field: "id" },
			{ headerName: botsString , field: "value" }
		];
		return (
			<div className="active-pages">
				< >
					{
						data?.length !== 0 ?
							<Table
								data={data}
								headers={headers}
							/> :
							<TrafficMessage message="There are currently no bots on the website." />
					}
				</>
			</div>
			// </div>
		);
	};

	return (
		<>
			<ActivePagesData />
		</>
	);
};

export default ActivePages;