import React from "react";
import { ResponsiveLine } from "@nivo/line";
import CustomTooltip from "SharedComponents/CustomTooltip/CustomTooltip";
const AffiliatesLineGrpah = ({ title, data,percentageSign }) => {
    const graphRef = React.useRef(null);

    const MyResponsiveLine = React.useMemo(() => {
        
        return (
            <ResponsiveLine
                data={data}
                margin={{ top: 20, right: 30, bottom: 70, left: 40 }}
                xFormat="time:%Y-%m-%d"
                xScale={{
                    format: '%Y-%m-%d',
                    precision: 'day',
                    type: 'time',
                    useUTC: false
                }}
                yScale={{
                    type: 'linear'
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    format: '%b %d',
                    tickRotation:25,
                    legendOffset: -12,
                    tickValues: 'every 2 days'
                }}
                axisLeft={{
                    
                    legendOffset: 12
                }}
                enablePoints ={false}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabel="data.yFormatted"
                pointLabelYOffset={-12}
                enableTouchCrosshair={true}
                useMesh={true}
                enableSlices='x'
                sliceTooltip={ (e)=><CustomTooltip data={e}  graphRef={graphRef}  percentageSign={percentageSign}/>}
            />
        )
    }, [data]

    );

    return (
        <>
            <div className="graph-heading">{title}</div>
            <div style={{ height: '100%', width: '100%' }} ref={graphRef} >
                {MyResponsiveLine}
            </div>

        </>
    )
};

export default AffiliatesLineGrpah;
