import React from "react";
import "./FinishedSessionsPanel.scss";
import HumanPercentage from "./FinishedSessionsComponents/HumansPercentage/HumanPercentage";
import LineGraph from "./FinishedSessionsComponents/FinisihedSessionsLineGraph/LineGraph";
import BotsPercentage from "./FinishedSessionsComponents/BotsPercentage/BotsPercentage";
import { useSelector } from "react-redux";
import { store } from "../../../../Redux/store";
import PieChart from "components/PieChart";
import { formatNumber } from "Utils/utils";
import Loader from "SharedComponents/Loader/Loader";

const FinishedSessionsPanel = (props) => {

    const finishedSessionsData = useSelector(state => state?.finishedSessions?.data || []);
    const websiteStatus = store.getState().websiteDetails?.data?.websiteDetails?.status;
    const botsString = websiteStatus === "protect" ? "Blocked Bots" : "Detected Bots";
    const { human, monitored, ok, bot, suspicious, goodbot } = finishedSessionsData?.currentData ?? [];
    const total = human + monitored + ok + bot + suspicious;
    const humansValue = human + monitored + ok + suspicious;
    const humanPercentage = getPercent(human + monitored + ok + suspicious);
    const botPercentage = getPercent(bot);
    const goodBotPercentage = getPercent(goodbot);
    const pieChartData = [
        { id: 'human', value: humanPercentage, color: "#662D91" },
        { id: 'good_bots', value: goodBotPercentage, color: "#49B65B" },
        { id: 'malicious_bots', value: botPercentage, color: "#E22937" }
    ];
    function getPercent(inp) {
        const value = ((inp / total) * 100).toFixed(1);
        return isNaN(value) ? 0 : value;
    };

    return (
        <div className="finishedSessions-graph-area">
            <LineGraph
                sessionBreakdownData={finishedSessionsData?.breakDownData}
                botsString={botsString}
                loading={props.loading}
                total={total}
                tooltip={"A daily summary based on the number of humans and malicious bots on your website. This data excludes good bots."}
            />
            <div className="finishedSessions-pie">
                {props.loading ? <Loader /> :
                    <>
                        <div style={{ width: '100%', height: '77%' }}>
                            <PieChart
                                data={pieChartData}
                                percentage={true}
                                legendClassName='finished-sessions-legend-section'
                                dimensions={{ top: 40, right: 180, bottom: 30, left: 30 }}
                            />
                        </div>
                        <div className="finishedSessions-section">
                            <div>
                                <div>Humans</div>
                                <div className="humansValues" >{formatNumber(humansValue)}</div>
                            </div>
                            <div>
                                <div>Malicious bots</div>
                                <div className="botsValues">{formatNumber(bot)}</div>
                            </div>
                            <div>
                                <div>Good bots</div>
                                <div className="goodBotsValues">{formatNumber(goodbot)}</div>
                            </div>
                        </div>
                    </>
                }
            </div>

        </div>
    );
};

export default FinishedSessionsPanel;
