import api from "../../../../veracityapi";
import { websiteCO2Data } from "../../../../Redux/actions";
import { getStartTimeStampFromString, getEndTimeStampFromString } from "../../../../SharedComponents/DateManager";

export default function Data(params, dispatch) {
	const websiteGUID = localStorage.getItem('selected_site');
	const start = getStartTimeStampFromString(params.timeRange);
	const EndOfYesterday = getEndTimeStampFromString(params.timeRange);

	const getBlockedCountTotal = new Promise((resolve, reject) => {
		api.HEAD(`/analytics/blockedCount/website/${websiteGUID}/${start}/${EndOfYesterday}`)
			.then(res => {
				resolve({
					blockedCount: res
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getSessionBreakdown = new Promise((resolve, reject) => {
		api.GET(`/analytics/session-breakdown/${websiteGUID}/${start}/${EndOfYesterday}/0/all`)
			.then(res => {
				resolve({
					breakDownData: res.item
				});
			}).catch((error) => {
				api.logout(error);
				console.log("CO2 Panel Error: ", error);
			});
	});

	const getMostBotReferrer = new Promise((resolve, reject) => {
		api.GET(`/analytics/website/${websiteGUID}/topbyfilter/referrer/${start}/${EndOfYesterday}/bot`)
			.then(res => {
				resolve({
					mostBlockedReferrer: res.item[0] ?? []
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getMostBotCountries = new Promise((resolve, reject) => {
		api.GET(`/analytics/website/${websiteGUID}/topbyfilter/country/${start}/${EndOfYesterday}/bot`)
			.then(res => {
				resolve({
					mostBlockedCountry: res.item[0] ?? []
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getMostBotLandingPage = new Promise((resolve, reject) => {
		api.GET(`/analytics/${websiteGUID}/type/landing/${start}/${EndOfYesterday}/bot/finishedData`, { limit: 1 })
			.then(res => {
				resolve({
					landingPage: res.items[0] ?? []
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getGoodBotsProviderBreakdown = new Promise((resolve, reject) => {
		api.GET(`/analytics/website/${websiteGUID}/breakdown/goodbots/all/${start}/${EndOfYesterday}`)
			.then(res => {
				resolve({
					goodBotsProviderBreakdown: res
				});
			}).catch((error) => {
				api.handleError(error);
			});
	});

	return new Promise((resolve, reject) => {
		Promise.all([getBlockedCountTotal, getMostBotCountries, getGoodBotsProviderBreakdown, getMostBotReferrer, getMostBotLandingPage, getSessionBreakdown]).then(async (values) => {
			const temp = {
				timeRange: params.timeRange,
			};
			for (let obj of values) {
				Object.assign(temp, obj);
			};
			dispatch(websiteCO2Data(temp));
			resolve(true);
		});
	});
};