import React from "react";
import { useSelector,useDispatch } from "react-redux";
import api from "../../../veracityapi";
import "./NewUser.scss";
import ManageCustomerData from "../../../SharedComponents/ManageCustomerData/ManageCustomersData";
const NewUser = (props) => {
	const dispatch=useDispatch();
	const [selectedItems, setSelectedItems] = React.useState([]);
	const [inviteEmail, setInviteEmail] = React.useState("");
	const [modalState, setModalState] = React.useState("invite");
	const websiteArray = useSelector(state => state.websitesList.data);
	const [errorReason, setErrorReason] = React.useState('');
	const customerGuid = localStorage.getItem("selected_customer");

	const handleCloseModal = () => {
		props.inviteUserModal(false);
		// document.body.style.background = "#f4f4f4";
	};

	const canConfirm = selectedItems.length > 0 ? true : false;

	const handleSelectedItems = (e) => {
		if (typeof selectedItems.find((elm) => elm.guid === e) === "undefined") {
			setSelectedItems([...selectedItems, {
				guid: e,
				permission: 'read'
			}]);
		} else {
			setSelectedItems(selectedItems.filter((elm) => elm.guid !== e))
		}
	};

	const resetStates = () => {
		setSelectedItems([]);
		setInviteEmail("");
		setModalState("invite")
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (canConfirm) {
			api.POST(`invite/role/customer`, {
				customerGUID: customerGuid,
				userEmail: inviteEmail,
				customer_permission: "read",
				default_website_permission: "none",
				websites_permissions: selectedItems
			}, {}).then(() => {
				ManageCustomerData({},dispatch)
				setModalState('success');
			}).catch((error) => {
				setErrorReason(` Reason: ${error.response.data.error ?? ''}.`);
				setModalState("failed");
			});
		};
	};

	return (
		<div className="new-user">
			{(modalState === 'invite') &&
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-name">Invite new users</h3>
						<h3 className="close-modal" onClick={handleCloseModal}>X</h3>
					</div>

					<div className="new-user-content">
						<form
							onSubmit={handleSubmit}>
							<label htmlFor="email">Email</label>
							<input
								name="email"
								value={inviteEmail}
								type="email"
								className="invite"
								placeholder="Email"
								required
								onChange={(e) => setInviteEmail(e.target.value)}
							/>
							<div className="checklist-section">
								<h3 className="permissions-header">Allow Permissions</h3>
								<span>Please select one</span>
								{websiteArray?.items?.map((obj) => (
									<div key={obj.guid} className="website-checklist">
										<input type="checkbox" onClick={() => handleSelectedItems(obj.guid)} />
										<span>{obj.website_name}</span>
									</div>

								))}
							</div>

							<button disabled={!canConfirm} className={canConfirm ? "confirm-button" : "confirm-button confirm-button--cant"}> Confirm</button>
						</form>
					</div>
				</div>
			}
			{(modalState === 'failed') &&
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-name">Invite new users</h3>
						<h3 className="close-modal" onClick={handleCloseModal}>X</h3>
					</div>
					<div className="new-user-content">
						<span>Your invite has failed.{errorReason}</span>
						<button className="confirm-button" onClick={() => resetStates()}>Try Again</button>
					</div>
				</div>
			}
			{(modalState === 'success') &&
				<div className="modal-content">
					<div className="modal-header">
						<h3 className="modal-name">Invite new users</h3>
						<h3 className="close-modal" onClick={handleCloseModal}>X</h3>
					</div>
					<div className="new-user-content">
						<span>Invite has been sent to {inviteEmail}.</span>
						<button className="confirm-button" onClick={resetStates}>Send New Invite</button>
					</div>
				</div>
			}
		</div>
	);
};

export default NewUser;