import moment from "moment-timezone";

export const getStartTimeStampFromString = (timeRange) => {
    const timeZone = localStorage.getItem('timezone') || 'UTC';
    const currentTimeAndDateInTimeZone = moment().tz(timeZone);
    switch (timeRange) {
        case 'Active Now':
            return currentTimeAndDateInTimeZone.subtract(30, 'minutes').unix();
        case 'Today':
            return currentTimeAndDateInTimeZone.startOf('day').unix();
        case 'Prev 7 Days':
            return currentTimeAndDateInTimeZone.subtract(7, 'days').startOf('day').unix();
        case 'Prev 14 Days':
            return currentTimeAndDateInTimeZone.subtract(14, 'days').startOf('day').unix();
        case 'Prev 28 Days':
            return currentTimeAndDateInTimeZone.subtract(28, 'days').startOf('day').unix();
        case 'This Month':
            return currentTimeAndDateInTimeZone.startOf('month').unix();
        case 'Last Month':
            return currentTimeAndDateInTimeZone.subtract(1, 'month').startOf('month').unix();
        default:
            if (timeRange) {
                const startDate = timeRange.split('-')[0].trim();
                return moment.tz(timeRange.split('-')[0], "Do MMM YY", timeZone).startOf('day').unix();
            }
            return null;
    }
};
export const getEndTimeStampFromString = (timeRange) => {
    const timeZone = localStorage.getItem('timezone') || 'UTC';
    const currentTimeAndDateInTimeZone = moment().tz(timeZone);

    switch (timeRange) {
        case 'Active Now':
            return currentTimeAndDateInTimeZone.subtract(1, "minute").unix();
        case 'Today':
            return currentTimeAndDateInTimeZone.endOf('day').unix();
        case 'Prev 7 Days':
        case 'Prev 14 Days':
        case 'Prev 28 Days':
            return currentTimeAndDateInTimeZone.subtract(1, 'day').endOf('day').unix();
        case 'This Month':
            return currentTimeAndDateInTimeZone.endOf('month').unix();
        case 'Last Month':
            return currentTimeAndDateInTimeZone.subtract(1, 'month').endOf('month').unix();
        default:
            if (timeRange) {
                const endDate = timeRange.split('-')[1].trim();
                return moment.tz(endDate, "Do MMM YY", timeZone).endOf('day').unix();
            }
            return null;
    }
};

export const getComparisonTimeStampFromString = (timeRange) => {
    const timeZone = localStorage.getItem('timezone') || 'UTC';
    const currentTimeAndDateInTimeZone = moment().tz(timeZone);

    switch (timeRange) {
        case 'Prev 7 Days':
            return currentTimeAndDateInTimeZone.subtract(14, 'days').startOf('day').unix();
        case 'Prev 14 Days':
            return currentTimeAndDateInTimeZone.subtract(28, 'days').startOf('day').unix();
        case 'Prev 28 Days':
            return currentTimeAndDateInTimeZone.subtract(56, 'days').startOf('day').unix();
        case 'This Month':
            return currentTimeAndDateInTimeZone.subtract(1, 'month').startOf('month').unix();
        case 'Last Month':
            return currentTimeAndDateInTimeZone.subtract(2, 'months').startOf('month').unix();
        default:
            if (timeRange) {
                const startDate = timeRange.split('-')[0].trim();
                return moment.tz(startDate, "Do MMM YY", timeZone).subtract(7, 'days').startOf('day').unix();
            }
            return null;
    }
};

export const utcTimeStringToLocalTimeString = (utcTimeString, inFormat = "", outFormat = "YYYY-MM-DD HH:mm:ss") => {
    const timeZone = localStorage.getItem('timezone') || 'UTC';
    return moment.utc(utcTimeString, inFormat).tz(timeZone).format(outFormat);
};

export const currentTimeAndDateInTimeZoneMinusMinutes = (minutes = 0) => {
    const timeZone = localStorage.getItem('timezone') || 'UTC';
    return moment().tz(timeZone).subtract(minutes, 'minutes').unix();
};
