import { useMemo } from "react";
import { ResponsivePie } from "@nivo/pie";

const PieChart = ({ data, dimensions, legend = true, legendClassName, percentage = false }) => {
    let percentageSign = percentage && '%';
    const CustomLegend = () => {
        return (
            <div className={legendClassName}>
                {
                    data.map((val, index) => (
                        <div className="legends-values" key={index}>
                            <div className="legend-keys" style={{ backgroundColor: val.color }} ></div>
                            <div>
                                <div>{val.value}{percentageSign}</div>
                                <div style={{ fontWeight: "lighter", textTransform: "capitalize" }}>{val.id.replace('_', " ")}</div>
                            </div>

                        </div>
                    )
                    )
                }
            </div>
        )
    };
    const Pie = useMemo(() => {
        return (
            <>
                <ResponsivePie
                    data={data}
                    margin={dimensions}
                    innerRadius={0.35}
                    padAngle={2}
                    activeOuterRadiusOffset={8}
                    borderWidth={2}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}
                    colors={{ datum: 'data.color' }}
                    enableArcLinkLabels={false}
                    arcLinkLabelsSkipAngle={14}
                    arcLinkLabelsTextOffset={8}
                    arcLinkLabelsTextColor={{ theme: 'background' }}
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: 'color' }}
                    enableArcLabels={false}
                    arcLabelsRadiusOffset={0.6}
                    arcLabelsSkipAngle={18}
                    tooltip={({
                        datum: {
                            id,
                            value,
                        }
                    }) => <div className="toolTipContent" >
                            <span style={{fontWeight:'bolder'}}>{id.replace('_', ' ')}</span>: <span style={{ fontWeight: 'lighter' }}>{value}{percentageSign}</span>
                        </div>}
                />
                {legend && <CustomLegend />}
            </>
        )
    }, [data]);
    return (
        <>
            {Pie}
        </>
    )
};

export default PieChart;
