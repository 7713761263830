import React from "react";
import "./ActiveCampaignPanel.scss";
import Table from "components/Table";
import Loader from "../../../SharedComponents/Loader/Loader";
import NoContent from "../../../SharedComponents/NoContent/NoContent";
import { formatNumber, formatCurrency } from "Utils/utils";

const ActiveCampaignPanelClassic = ({ adType, className, data, loading, baseURL }) => {

    const values = data && data?.[adType]?.values;
    const total = data && data?.[adType]?.summary;
    let url = `${baseURL}/${adType}`;
    let id = values?.map((item) => Object.keys(item)?.includes('link_id') ? 'link_id' : 'campaign_id');

    const headers = [
        { headerName: id !== undefined && id[0] === 'campaign_id' ? 'Campaign Name' : `Link Notes`, field: id !== undefined && id[0] === 'campaign_id' ? 'campaign_name' : 'link_notes', url: url },
        { headerName: 'Spend', field: 'spend', valueFormatter: (val) => `£${formatCurrency(val)}`, url: url },
        { headerName: 'Impressions', field: 'impressions', valueFormatter: (val) => `${formatNumber(val)}`, url: url },
        { headerName: 'Paid Clicks', field: 'clicks', valueFormatter: (val) => `${formatNumber(val)}`, url: url },
        { headerName: 'Human Visits', field: 'humans', valueFormatter: (val) => `${formatNumber(val)}`, url: url },
        { headerName: 'Cost Per Human Visit', field: 'human_click_cost', valueFormatter: (val) => `£${formatCurrency(val)}`, url: url },
        { headerName: 'Invalid Clicks %', field: 'invalid_clicks_percentage', valueFormatter: (val) => `${(val)?.toFixed(1)}%`, url: url }
    ];

    return (
        <div className={`active-campaign-panel ${className}`} >
            {loading ? <Loader /> :
                <>
                    {
                        values?.length ?
                            <Table
                                headers={headers}
                                data={values}
                                footerData={[total]}
                                isLink={true}
                                url={url}
                                id={id[0]}
                            /> :
                            <NoContent />
                    }
                </>
            }
        </div>
    )
}
export default ActiveCampaignPanelClassic;
