import React from "react";
import "./table.scss";
import { Link } from "react-router-dom";

const Table = ({ headers, data, footerData, isLink = false, id }) => {
    return (
        <div className="table-container">
            <div className="table-rows">
                {headers?.map((val, index) => {
                    return (
                        <div key={index} className="table-head" title={val.headerName}>
                            {val.headerName}
                        </div>
                    )
                }
                )}
            </div>
            {
                data?.map((val) =>
                    <div className="table-data-rows" >
                        {headers.map((value, index) => {
                            return (
                                <>
                                    {
                                        isLink ?
                                            <Link
                                                to={`${value.url}/${val[id]}`}
                                                style={{ textDecoration: 'none', color: 'black'}} 
                                                key={index}
                                                className="table-cell"
                                                onClick={value.action ? () => value?.action(val) : value?.action}
                                                title={value?.title ? value?.title(val[value.field]) : val[value.field]}
                                            >
                                            {value?.valueFormatter ? value?.valueFormatter(val[value.field]) : val[value.field]}

                                            </Link> :
                                            <div
                                                key={index}
                                                className="table-cell"
                                                onClick={value.action ? () => value?.action(val) : value?.action}
                                                title={value?.title ? value?.title(val[value.field]) : val[value.field]}
                                            >
                                                {value?.valueFormatter ? value?.valueFormatter(val[value.field]) : val[value.field]}
                                            </div>

                                    }
                                </>
                            )
                        })}
                    </div>
                )
            }
            {
                footerData?.map((val) =>
                    <div className="table-footer" >
                        {headers.map((value, index) => {
                            return (
                                <div
                                    key={index}
                                    className="table-cell"
                                    style={{ fontWeight: 'bold' }}
                                    title={value?.title ? value?.title(val[value.field]) : val[value.field]}
                                >
                                    {value?.valueFormatter ? value?.valueFormatter(val[value.field]) : val[value.field]}
                                </div>
                            )
                        })}
                    </div>
                )
            }
        </div>

    )
};


export default Table;