import React from "react";
import { countryToAlpha2 } from "country-to-iso";
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";
import TrafficMessage from "SharedComponents/TrafficMessage/TrafficMessage";
import { useLocation } from "react-router-dom";
import { formatNumber } from "Utils/utils";
import Table from "components/Table";

const CountriesTable = (props) => {
	const { pathname } = useLocation();

	const regionNames = new Intl.DisplayNames(
		['en'], { type: 'region' }
	);

	const formatData = (data) => {
		let formattedData = [];
		try {
			let arr = [];
			let secArray = [];
			for (let key in data) {
				let val = (Object.entries(data[key]))
				for (let i = 0; i < val.length; i++) {
					arr.push(val[i])
				}
			};

			for (let j = 0; j < arr.length; j++) {
				let newArray = {
					id: countryToAlpha2(arr[j][0]) === null ? "unknown" : countryToAlpha2(arr[j][0]),
					value: arr[j][1]
				}
				secArray.push(newArray)
			};

			secArray.reduce(function (res, value) {
				if (!res[value.id]) {
					res[value.id] = { id: value.id, value: 0 };
					formattedData.push(res[value.id])
				}
				res[value.id].value += value.value;
				return res;
			}, {});
		} catch (error) {
			console.log("Country format error:", error)
		}
		return formattedData;
	};

	const data = formatData(props?.data);
	const headers = [
		{ headerName: "Country", field: "id", valueFormatter: (val) => val === "unknown" || val === "Others" || val=== "null" ? "Unknown" : (regionNames.of(countryToAlpha2(val))) },
		{ headerName: "Session", field: "value", valueFormatter: (val) => formatNumber(val) }
	];

	return (
		<div className="countries-list">
			<Table
				headers={headers}
				data={data.sort((a, b) => b.value - a.value)}
			/>
		</div>
	)

};

export default CountriesTable;