import React from "react";
import { useSelector } from "react-redux";
import BotActivityTile from "./BotActivityComponent/BotActivityTile"
import { store } from "../../Redux/store";
import api from "../../veracityapi";
import "./BotActivity.scss";
import Alert from "components/Alert";
import { wtpDisableMessage } from "components/AFP/Constants";

const Protection = () => {
	const loadingState = useSelector(store => store?.loadData?.data);
	const sidetoggle = useSelector(state => (state.toggle.toggle));
	const [loading, setLoading] = React.useState(loadingState);
	const graphsHeading = store.getState().websiteDetails?.data?.websiteDetails?.status === "protect" ? "Blocked" : "Detected";
	const detectModes = useSelector(state => state?.detectModes);


	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};

	React.useEffect(() => {
		setLoading(loadingState)
	}, [loadingState])
	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	return (
		<div className="analytics-container">
			<div className={`display-area ${sidetoggle ? "display-area--small" : "display-area--large"}`}>
				{detectModes?.data?.wtp_status == "off" &&
					<Alert message={wtpDisableMessage}></Alert>
				}
				<BotActivityTile graph='Bot Threat' loading={loading} setLoading={setLoading} />
				<BotActivityTile graph="Finished Sessions" loading={loading} setLoading={setLoading} />
				<BotActivityTile graph='Good Bots and CO2' loading={loading} setLoading={setLoading} />
				<BotActivityTile graph='Bot Sources' loading={loading} setLoading={setLoading} />
			</div>
		</div>
	)
};

export default Protection;
