import api from "veracityapi";
import { botRiskDetailsForBotActivity } from "Redux/actions";
import { getStartTimeStampFromString, getEndTimeStampFromString } from "SharedComponents/DateManager";

const BotRiskControllerforBotActivity = (params, dispatch) => {
    const websiteGUID = localStorage.getItem('selected_site');
    const start = getStartTimeStampFromString(params.timeRange);
    const EndOfYesterday = getEndTimeStampFromString(params.timeRange);

    const getWebsiteRiskType = new Promise((resolve, reject) => {
        api.GET(`/analytics/website/${websiteGUID}/websiteRiskType/${start}/${EndOfYesterday}`)
            .then((res) => {
                resolve(res)
            }).catch((err) => {
                api.handleError(err)
            })
    });
    const doubleBreakDownBotClassification = new Promise((resolve, reject) => {
        api.GET(`/session/website/${websiteGUID}/doublebreakdown/bot_classification/${start}/${EndOfYesterday}/0/bot`, { active: false, finished: true })
            .then((res) => {
                resolve({
                    botClassification: res
                })
            }).catch((err) => {
                api.handleError(err)
            })
    });

    const doubleBreakDownBotRiskType = new Promise((resolve, reject) => {
        api.GET(`/session/website/${websiteGUID}/doublebreakdown/bot_risk_type/${start}/${EndOfYesterday}/0/bot`, { active: false, finished: true })
            .then((res) => {
                resolve({
                    botRiskType: res
                })
            }).catch((err) => {
                api.handleError(err)
            })
    });

    const getWebsiteThreatAssessment = new Promise((resolve, reject) => {
        api.GET(`/analytics/website/${websiteGUID}/websiteThreatAssessment/${start}/${EndOfYesterday}`)
            .then((res) => {
                resolve({
                    websiteThreatAssessment: res
                })
            }).catch((err) => {
                api.handleError(err)
            })
    });

    return new Promise((resolve, reject) => {
        Promise.all([getWebsiteRiskType, doubleBreakDownBotClassification, getWebsiteThreatAssessment, doubleBreakDownBotRiskType]).then(async (values) => {
            const temp = {
            };
            for (let obj of values) {
                Object.assign(temp, obj);
            };
            dispatch(botRiskDetailsForBotActivity(temp));
            resolve(true);
        });
    });
};

export default BotRiskControllerforBotActivity;