import React from "react";
import RiskMeasurement from "components/Today/TodayPanels/BotRisk/BotRiskComponents/RiskMeasurement";
import { useSelector } from "react-redux";
import { riskLevels, colors, classificationColors, botClassificationName } from "components/AFP/Constants";
import MyResponsiveRadialBar from "components/RadialBar";
import Card from "components/Card";
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";
import Loader from "SharedComponents/Loader/Loader";

const BotRiskForBotActivity = ({ loading }) => {
	const finishedSessionsData = useSelector(state => state?.finishedSessions?.data || []);
	const websiteRiskData = useSelector(state => state?.botActivityBotRiskDetails?.data);
	const doubleBreakdown = useSelector(state => state?.botActivityBotRiskDetails?.data?.botClassification);
	const websiteThreatAssessment = useSelector((state) => state?.botActivityBotRiskDetails?.data?.websiteThreatAssessment);
	const botClassification = useSelector((state) => state?.botTypeClassification?.data?.botTypeClassification);
	const websiteStatus = useSelector((state) => state.websiteDetails?.data?.websiteDetails?.status);

	function getRangeName() {
		switch (finishedSessionsData.timeRange) {
			case "Prev 7 Days":
				return "in the last 7 days"
			case "Prev 14 Days":
				return "in the last 14 days"
			case "Prev 28 Days":
				return "in the last 28 days"
			case "this month":
				return "in the last 7 days"
			case "Last Month":
				return "in the last month"
			default:
				return "in the selected time frame"
		}
	};
	function getTickSize() {
		const values = Object.keys(websiteRiskData?.item).length ?? 0;
		if (values > 20) {
			return 15
		} else {
			return values
		}
	};

	const totalBotsLandings = Object.values(websiteThreatAssessment.item)?.reduce((acc, item) => {
		return acc += parseInt(item?.total_landings)
	}, 0);

	const tickValues = getTickSize();
	const rangeName = getRangeName();

	const formatBotTypeData = () => {
		let botTypeTotals = {};
		for (let dates in doubleBreakdown.item) {
			for (let id in doubleBreakdown.item[dates].bot) {
				if (id !== "" && id != 0) {
					if (botTypeTotals[id] === undefined) {
						botTypeTotals[id] = {
							id: parseInt(id),
							label: botClassificationName[id],
							value: doubleBreakdown?.item[dates]?.bot[id],
							color: classificationColors[botClassificationName[id]],
						}
					} else {
						botTypeTotals[id].value += doubleBreakdown?.item[dates]?.bot[id];
					}
				}

			}
		};
		return Object.values(botTypeTotals).sort(function (a, b) {
			return b.value - a.value;
		});
	};

	const formattedBotTypeData = formatBotTypeData();
	
	const formatDataForRadialGraph = () => {
		const result = [{ id: "Low", data: [] }, { id: "Medium", data: [] }, { id: "High", data: [] }, { id: "Dangerous", data: [] }];

		for (let keys in formattedBotTypeData) {
			for (let data in botClassification.items) {
				for(let id in result){
					if (botClassification.items[data].bot_classification_id === formattedBotTypeData[keys].id) {
						if(result[id]?.id=== botClassification.items[data].risk_level){
							result[id].data.push({
								x: `${botClassification.items[data].classification} (${botClassification.items[data].risk_level})`,
								y: formattedBotTypeData[keys].value,
								color: classificationColors[botClassification.items[data].classification]
							})
						}
					}
				}
			}
		};
		return result
	};

	const radialBarData = formatDataForRadialGraph();

	const formatBotBreakdownData = () => {
		const data = JSON.parse(JSON.stringify(Object.values(websiteThreatAssessment.item)));
		for (let keys in data) {
			for (let val in riskLevels) {
				if (riskLevels[val] === data[keys].bot_risk_type) {
					data[keys].bot_risk_type = val;
				}
			}
		};
		return data.sort((a, b) => Number(b.bot_risk_type) - Number(a.bot_risk_type));
	};

	const botBreakdownData = formatBotBreakdownData();

	return (
		<div className="bot-risk-section">
			<RiskMeasurement websiteRiskData={websiteRiskData} loading={loading} tickValues={tickValues} title={websiteStatus === "protect" ? "Mitigated Threat Level" : "Threat Level"} rangeName={rangeName} />
			<div className="blocked-threat-level">
				<section className="header-section">
					<div className="graph-heading">Blocked Bot Threat Level</div>
					<GraphsToolTip message='Bot threat level details' />
				</section>
				{loading ?
					<Loader />
					:
					<MyResponsiveRadialBar
						data={radialBarData}
						legendClassName='radial-legend-section'
						legendData = {formattedBotTypeData}
					/>
				}
			</div>
			<div className='bot-type-breakdown'>
				<section className="header-section">
					<div className="graph-heading">Blocked Bot Type Breakdown</div>
					<GraphsToolTip message='Bot type breakdown' />
				</section>
				<div className='bot-details'>
					{
						loading ? <Loader /> :
							<>
								{
									botBreakdownData?.map((item, index) =>
										<Card color={colors[item.bot_risk_type]} backgroundColor='#F4F4F4' key={index}>
											<>
												<div style={{ background: colors[item.bot_risk_type] }} className='bot-title'>{item.bot_classification}</div>
												<div className='bot-spec'>
													<div className='bot-total-value'>{item.total_landings}</div>
													<div className='bot-percentage'>{((item?.total_landings / totalBotsLandings) * 100).toFixed(1)}%</div>
													<div className='targeted-bots'>Most targeted page:</div>
													<div className='bots-url'>{decodeURIComponent(item.most_popular_landing_page)}</div>
												</div>

												<div className='bot-dangerous-level' style={{ background: colors[item.bot_risk_type] }}>{riskLevels[item.bot_risk_type]}</div>
											</>
										</Card>)
								}
							</>
					}

				</div>
			</div>
		</div>
	)

};

export default BotRiskForBotActivity;