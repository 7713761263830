import React from "react";
import CurrentActivityPanel from "../TodayPanels/CurrentActivityPanel/CurrentActivity";
import TodayFinisihedSessions from "../TodayPanels/TodayFinishedSessionsPanel/TodayFinishedSessions";
import DateDropDown from "../../../SharedComponents/DateDropDown/DateDropDown";
import BotDetails from "../TodayPanels/BotDetails/BotDetails";
import { useDispatch, useSelector } from "react-redux";
import BotDetailsData from "../TodayPanels/BotDetails/BotDetailsData";
import BotRisk from "../TodayPanels/BotRisk/BotRisk";
import { Link } from "react-router-dom";
import { riskLevelsColours } from "components/AFP/Constants";

const TodayTile = (props) => {
	const dispatch = useDispatch();
	const botDetailsData = useSelector(state => state?.botDetails.data);
	const [graph, setGraph] = React.useState(null);
	const parentElm = React.useRef(null);
	const [value, setValue] = React.useState({
		graph: null,
		timeRange: botDetailsData?.timeRange
	});
	const dateDropDownValues = ["Active Today", "Active Now"];
	
	const graphSelect = () => {
		switch (props.graph) {
			case 'Current Bot Activity':
				return <CurrentActivityPanel value={value} setValue={setValue} />
			case "Today's mitigated threat level":
				return <BotRisk />
			case "Malicious Bot Details":
				return <BotDetails />
			case "Today's Finished Bot Analysis":
				return <TodayFinisihedSessions value={value} setValue={setValue} />
			default:
				return <></>
		};
	};

	React.useEffect(() => {
		if (value.timeRange !== botDetailsData?.timeRange) {
			setValue((pre) => ({
				...pre,
				timeRange: value?.timeRange
			}));
			BotDetailsData({ timeRange: value?.timeRange }, dispatch, 1)
		}

	}, [value.timeRange]);

	React.useEffect(() => {
		setGraph(graphSelect());
	}, [props.graph, value]);


	return (
		<>
			<div
				className="graph-tile"
				style={{ width: '95%' }}
				ref={parentElm}
			>
				<div className="graph-tile-header">
					<div className="header-details">
						<h2>{props.graph}{props.graph === "Today's mitigated threat level" &&<>: <span style={{backgroundColor:riskLevelsColours[props.todayThreatLevel]}}>{props.todayThreatLevel}</span></>  } </h2>
					</div>
					<div className="button-box">
						<div className="date-dropdown-container">
							{/* Hidden - active sessions doesn't work! {props.graph === "Bot Details" &&
								<DateDropDown setValue={setValue} value={value} dateDropDownValues={dateDropDownValues} />
							} */}
						</div>
					</div>
				</div>
				<div className="graph-body">
					{graph}
				</div>

			</div>



		</>

	)
};

export default TodayTile;
