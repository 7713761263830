import React from "react";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { riskLevels, riskLevelsPerClassification } from "components/AFP/Constants";

const RadialBarTooltip = ({ data, }) => {
    return (
        <div className="toolTipContent">
            <><span style={{ fontWeight: 'bolder' }}>{data?.data.x}:</span> <span>{data?.data.y}</span></>
        </div>
    )
};

const MyResponsiveRadialBar = ({ data, legend = true, legendClassName, legendData }) => {
    const CustomLegend = ({ data }) => {
        return (

            <div className={legendClassName}>
                {
                    legendData?.map((val, index) => (
                        <div className="legends-values" key={index}>
                            <div className="legend-keys" style={{ backgroundColor: val.color }} ></div>
                            <div>{val.label} ({riskLevelsPerClassification[val.label]})</div>
                        </div>
                    )
                    )
                }
            </div>
        )
    };
    return (
        <>
            <ResponsiveRadialBar
                data={data}
                valueFormat=">-.2f"
                padding={0.4}
                animate={true}
                cornerRadius={2}
                colors={({ data }) => data.color}
                margin={{ top: 30, right: 120, bottom: 70, left: 40 }}
                radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
                circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
                tooltip={({ bar }) => <RadialBarTooltip data={bar} />}
            />
            {legend && <CustomLegend data={data} />}
        </>
    );
}
export default MyResponsiveRadialBar;