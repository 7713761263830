export function toggle(state) {
	return {
		type: "TOGGLE",
		payload: state
	};
};

export function AFPMenuToggleAction(state) {
	return {
		type: "AFP_MENU_TOGGLE",
		payload: state
	};
};

export function submenuToggle(state) {
	return {
		type: "SET_SUBMENU_TOGGLE",
		payload: state
	};
};

export function finishedSessions(state) {
	return {
		type: "SET_FINISHED_SESSIONS_DATA",
		payload: state
	};
};

export function websiteData(state) {
	return {
		type: "SET_WEBSITE_DATA",
		payload: state
	};
};

export function sessionGeography(state) {
	return {
		type: "SET_SESSIONS_GEOGRAPHY_DATA",
		payload: state
	};
};

export function humanActivity(state) {
	return {
		type: "HUMAN_ACTIVITY",
		payload: state
	};
};

export function blockedSessionsGeography(state) {
	return {
		type: "SET_BLOCKED_SESSIONS_GEOGRAPHY_DATA",
		payload: state
	};
};

export function todayActiveSessions(state) {
	return {
		type: "SET_CURRENTLY_ACTIVE_SESSIONS",
		payload: state
	};
};

export function todayFinisihedSessions(state) {
	return {
		type: "SET_TODAY_FINISHED_SESSIONS",
		payload: state
	};
};

export function websiteInsights(state) {
	return {
		type: "SET_WEBSITE_INSIGHTS",
		payload: state
	};
};

export function dashboardSelectedGraphs(state) {
	return {
		type: "SET_DASHBOARD_SELECTED_GRAPHS",
		payload: state
	};
};

export function evaluationStatus(state) {
	return {
		type: "SET_EVALUATION_GRAPHS",
		payload: state
	};
};

export function websitePerformance(state) {
	return {
		type: "WEBSITE_PERFORMANCE",
		payload: state
	};
};

export function websiteCO2Data(state) {
	return {
		type: "WEBSITE_CO2",
		payload: state
	};
};

export function websiteList(state) {
	return {
		type: "WEBSITE_LIST",
		payload: state
	};
};

export function detectModes(state) {
	return {
		type: "DETECT_MODES",
		payload: state
	};
};

export function BaselineDataAct(payload) {
	return {
		type: 'UPDATE_BASELINE_DATA',
		payload
	}
};

export function CurrentDataAct(payload) {
	return {
		type: 'UPDATE_CURRENT_DATA',
		payload
	}
};

export function todaySessionsInfo(payload) {
	return {
		type: 'SET_TODAY_SESSIONS_INFO',
		payload
	}
};

export function blockedSessionsInfo(payload) {
	return {
		type: 'SET_BLOCKED_SESSIONS_INFO',
		payload
	}
};

export function adNetworkPlatforms(payload) {
	return {
		type: "UPDATE_AVAILABLE_AD_NETWORKS",
		payload
	}
};

export function sessionAffiliates(payload) {
	return {
		type: "SESSION_AFFILIATES_INFO",
		payload
	}
};

export function botDetails(payload) {
	return {
		type: 'SET_BOTDETAILS',
		payload
	}
};

export function usersDetails(payload) {
	return {
		type: 'SET_USERSDETAILS',
		payload
	}
};

export function loadDataForAllTabs (payload){
	return {
		type:"LOAD_DATA",
		payload
	}
};

export function botRiskDetails (payload){
	return {
		type:"BOTRISK_DETAILS",
		payload
	}
};
export function botRiskDetailsForBotActivity (payload){
	return {
		type:"BOTRISK_DETAILS_FOR_BOTACTIVITY",
		payload
	}
};
export function botTypeClassification (payload){
	return {
		type:"BOT_TYPE_CLASSIFICATION",
		payload
	}
};

export function licenceKeyData (payload){
	return {
		type:"LICENCE_KEY_DATA",
		payload
	}
};

// export function switchWebsite(payload){
// 	return {
// 		type:"SWITCH_REQUEST",
// 		payload
// 	}
// };


