import React from "react";
import { useSelector } from "react-redux";
import "./BlockedBotsCo2.scss";
import { store } from "Redux/store";
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";
import CO2LineGraph from "./BlockedBotsCO2Components/CO2LineGraph";
import Loader from "SharedComponents/Loader/Loader";
import PieChart from "components/PieChart";
import {goodBotsTypesColors} from "../../../AFP/Constants";

const BlockedBotsCo2 = (props) => {
	const data = useSelector(state => state.websiteCO2.data);
	const websiteStatus = store.getState()?.websiteDetails?.data?.websiteDetails.status === "protect" ? "blocked" : "detected"
	const botsString = websiteStatus === "protect" ? "Blocked Bots" : "Detected Bots";
	const finishedSessionsData = useSelector(state => state?.finishedSessions?.data || []);
	const goodBotsProviderBreakdown = data?.goodBotsProviderBreakdown;
                             
	let botsTimeText;

	switch (finishedSessionsData.timeRange) {
		case "Prev 7 Days":
			botsTimeText = "in the last 7 days"
			break;
		case "Prev 14 Days":
			botsTimeText = "in the last 14 days"
			break;
		case "Prev 28 Days":
			botsTimeText = "in the last 28 days"
			break;
		case "this month":
			botsTimeText = "in the last 7 days"
			break;
		case "Last Month":
			botsTimeText = "in the last month"
			break;
		default:
			botsTimeText = "in the selected time frame"
			break;
	}

	let blockedBotCount = data?.blockedCount.totalcount
	let CO2SavedWithMeasurement
	(blockedBotCount * 1.5 < 1000) ? CO2SavedWithMeasurement = `${(blockedBotCount * 1.5).toFixed(1)}g` : CO2SavedWithMeasurement = `${((blockedBotCount * 1.5) / 1000).toFixed(1)}kg`

	let CO2SavingsEquivelentCars = ((blockedBotCount * 5.208333333333333) / 1600)?.toFixed(1)
	let CO2SavingsEquivelentPhones = (blockedBotCount * 66 / 1000).toFixed(1);

	const formatDataForGoodBotTypes = () => {
		const result =[];
		const total= Object.values(goodBotsProviderBreakdown?.item).reduce((acc, item) =>acc+item, 0);
		for(let key in goodBotsProviderBreakdown?.item){
			const obj = {
				id: key,
				lable:key,
				value: ((goodBotsProviderBreakdown?.item[key]/total)*100)?.toFixed(1),
				color: goodBotsTypesColors[key]
			}
			result.push(obj)
		};
		return result;
	};

	const goodBotTypes = formatDataForGoodBotTypes();
	return (
		<>
			<div className="good-bots-content">
				<div className="header-text-one">
					{props.loading ? <Loader /> :
						<>
							<div className="protection-header">
								<div className='graph-heading'>Good Bot Types</div>
								<div><GraphsToolTip message={`This is a summary of the bots we have ${websiteStatus} you against.`} /></div>
							</div>
							<div style={{ width: '100%', height: '80%' }} >
								<PieChart
									data={goodBotTypes}
									percentage={true}
									legendClassName='goodbots-legend-section'
									dimensions={{ top: 40, right: 80, bottom: 30, left: -140 }}
								/>
							</div>
						</>
					}
				</div>


				<div className="header-text-two">
					{props.loading ? <Loader /> :
						<>
							<div className="protection-header">
								<div className='graph-heading'>{websiteStatus === "detected" ? "Bot CO2 Wastage" : "CO2 Savings"}</div>
								<div style={{ textAlign: "end" }}><GraphsToolTip message={<p><a href="https://veracitytrustnetwork.com/blog/industry-insight/carbon-savings-from-bot-reduction/" style={{ marginTop: 10, color: "white" }} target="_new">Click here </a>to learn more about this panel.</p>} /></div>
							</div>
							<section className='CO2-section'>
								<div className='emmision-section'>
									<div className='emmision-value'>{CO2SavedWithMeasurement}</div>
									<div className='emmision-text'>CO2 emmisions saved</div>
								</div>
								<div className='equivalent-section'>
									<div className='equivalent-heading'>Equivalent to</div>
									<div className='equivalent-value'>{CO2SavingsEquivelentCars}</div>
									<div className='equivalent-description'>Miles in a petrol car</div>
								</div>
								<div className='smartphone-section'>
									<div className='smartphone-cycle-value'>{CO2SavingsEquivelentPhones}</div>
									<div className='smartphone-cycle-description'>CO2 emmisions saved</div>
								</div>
							</section>
							<CO2LineGraph
								finishedSessionsData={finishedSessionsData}
								botsString={botsString}
								// loading={props.loading}
								websiteStatus={websiteStatus}
							/>
						</>}
				</div>
			</div>
		</>
	)
};

export default BlockedBotsCo2;