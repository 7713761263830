import { React } from "react";
import './index.scss';

const Card = ({ color, children, backgroundColor }) => {
    return (

        <div className='card-container' style={{ borderColor: color, backgroundColor:backgroundColor }}>
            {children}
        </div>
    )
};

export default Card;