import React from "react";
import { useSelector } from "react-redux";
import api from "../../../../veracityapi";
import { useDispatch } from "react-redux";
import { websiteData } from "../../../../Redux/actions"
import "./EditUser.scss";

const EditUser = (props) => {
	const dispatch = useDispatch();
	const fullWebsiteDetails = useSelector(state => state?.users.data);
	const safeFullWebsiteDetails = JSON.parse(JSON.stringify(fullWebsiteDetails));
	const usersWebsitesPermsArr = fullWebsiteDetails?.items;	
	const websiteArray = useSelector(state => state.websitesList.data);
	const customerGUID = localStorage.getItem("selected_customer");
	const userPerms = usersWebsitesPermsArr?.find((user) => user.guid === props.editUserModal);
	const [selectedItems, setSelectedItems] = React.useState([]);
	const [modalState, setModalState] = React.useState("edit");
	const userWebsitePermissions = userPerms!==undefined && [...userPerms?.website_permissions];
	const handleCloseModal = () => {
		props.setEditUserModal(false);
		// document.body.style.background = "#f4f4f4";
	};

	React.useEffect(() => {
		const tempArr = []
		for (const website of websiteArray.items) {
			const tempSearch = userWebsitePermissions?.find((obj) => obj.guid === website.guid);
			if (typeof tempSearch !== 'undefined') {
				tempArr.push({
					website_name: website.website_name,
					guid: tempSearch.guid,
					permission: tempSearch.permission
				});
			} else {
				tempArr.push({
					website_name: website.website_name,
					guid: website.guid,
					permission: 'none'
				});
			};
			setSelectedItems(tempArr);
		};
	}, []);

	const onConfirm = async (e) => {
		e.preventDefault();
		setModalState('waiting');		

		api.PUT(`customer/${customerGUID}/user/${props.editUserModal}`, { 
			"role_body" : {
				"customer_permission": "read",
				"default_website_permission": "none",
				"websites_permissions": selectedItems
			}
		} ).then(async () => {
				safeFullWebsiteDetails.users.items.find((obj) => obj.guid === props.editUserModal).website_permissions = selectedItems;		
				dispatch(websiteData({...safeFullWebsiteDetails}));
				setModalState('success');
			}).catch(async () =>{
				setModalState('failed');
			});
	};

	const canConfirm = selectedItems.filter((obj) => obj.permission !== "none").length;

	const handleSelectedItems = (e) => {
		const obj = selectedItems.find((elm) => elm.guid === e);
		if (obj.permission !== "none") {
			setSelectedItems([...selectedItems.map((mapObj) => {
				if (mapObj.guid === e) {
					mapObj.permission = "none"
					return mapObj
				}
				return mapObj
			})]);
		} else {
			setSelectedItems([...selectedItems.map((mapObj) => {
				if (mapObj.guid === e) {
					mapObj.permission = "read"
					return mapObj
				}
				return mapObj
			})]);
		};
	};
	const userName = `${userPerms.first_name} ${userPerms.last_name}`
	return (
		<div className="edit-user-modal">
			{(modalState === "edit") &&
				<div className="edit-user-modal-content">
					<div className="modal-header">
						<h3 className="modal-name">Change website access for {userName}</h3>
						<h3 className="close-modal" onClick={handleCloseModal}>X</h3>
					</div>

					<div className="new-user-content">
						<form
							onSubmit={onConfirm}
						>
							<div className="checklist-section">
								<span>Please select at least one</span>
								{selectedItems.map((obj) => (
									<div key={obj.guid} className="website-checklist">
										<div className={`modal-toggle-button ${(obj.permission !== 'none') ? 'active' : 'inactive'}`}>
											<input
											type="checkbox"
											id={`ID:${obj.guid}`}									
											onClick={() => handleSelectedItems(obj.guid)}									
											checked={obj.permission !== 'none'}
											/>
											<label for={`ID:${obj.guid}`} className="modal-slider"></label>
										</div>
										<div>{obj.website_name}</div>
									</div>
								))}
							</div>
							<button disabled={!canConfirm} className={canConfirm ? "edit_user-confirm-button" : "edit_user-confirm-button edit_user-confirm-button--cant"}>Save</button>
							<button className="edit-user-cancel">Cancel</button>
						</form>
					</div>
				</div>
			}
			{(modalState === "waiting") &&
				<div className="edit-user-modal-content">
					<div className="modal-header">
						<h3 className="modal-name">Change website access for {userName}</h3>
						{/* <h3 className="close-modal" onClick={handleCloseModal}>X</h3> */}
					</div>
					<div className="new-user-content">
						<span className="edit-user-modal-status-text">
							Processing please wait
						</span>
						<button style={{visibility: 'hidden'}} onClick={handleCloseModal} className="confirm-button" > Close </button>
					</div>
				</div>
			}
			{(modalState === "success") &&
				<div className="edit-user-modal-content">
					<div className="modal-header">
						<h3 className="modal-name">Change website access for {`${userPerms.first_name} ${userPerms.last_name}`}</h3>
						<h3 className="close-modal" onClick={handleCloseModal}>X</h3>
					</div>
					<div className="new-user-content">
						<span className="edit-user-modal-status-text">
							Access update successful
						</span>
						<button onClick={handleCloseModal} className="confirm-button" > Close </button>
					</div>
				</div>
			}
			{(modalState === "failed") &&
				<div className="edit-user-modal-content">
					<div className="modal-header">
						<h3 className="modal-name">Change website access for {`${userPerms.first_name} ${userPerms.last_name}`}</h3>
						<h3 className="close-modal" onClick={handleCloseModal}>X</h3>
					</div>
					<div className="new-user-content">
						<span className="edit-user-modal-status-text">
							Access update failed
						</span>
						<button onClick={handleCloseModal} className="confirm-button" > Close </button>
					</div>
				</div>
			}
		</div>
	);
};

export default EditUser;