import React from "react";
import { formatNumber } from "Utils/utils";
import { legendColors } from "components/AFP/Constants";
import PieChart from "components/PieChart";

const VisitedBots = (props) => {
	const websiteStatus = props.botsString;
	const totalCount = formatNumber(props?.activeBots?.totalcount);
	const todayBlockedBots = formatNumber(props.todayBlockedBots?.totalcount);
	const finishedSession = props.finishedSessions;

	const dataFormation = () => {
		const result = [];
		let values = {
			humans: 0,
			good_bots: 0,
			malicious_bots: 0
		};
		for (let key in finishedSession) {
			values.humans += finishedSession[key].human
			values.malicious_bots += finishedSession[key].bot
			values.good_bots += finishedSession[key]?.good_bot ?? 0
		};
		const { humans, good_bots, malicious_bots } = values;
		const total = humans + good_bots + malicious_bots;
		for (let keys in values) {
			result.push(
				{
					id: keys,
					value: isNaN(((values[keys] / total) * 100).toFixed(1)) ? 0 : ((values[keys] / total) * 100).toFixed(1),
					color: legendColors[keys]
				}
			)
		};
		return result
	};

	const formattedData = dataFormation();

	const CurrentBots = () => {
		return (
			<>
				<div className='currentBots-container'>
					<div className='maliciousBots-section'>
						<div className="lastMins-bots">
							<div>Malicious bots {websiteStatus} in last 30 minutes</div>
							<div>{totalCount}</div>
						</div>
						<div className="today-bots">
							<div>Malicious bots {websiteStatus} today</div>
							<div>{todayBlockedBots}</div>
						</div>

					</div>
					<div className="traffic-section">
						<div>Today's traffic breakdown</div>
						<div style={{ width: "100%", height: "100%" }}>
							<PieChart
								data={formattedData}
								percentage={true}
								legendClassName='todayPie-legend-section'
								dimensions={{ top: 10, right: 150, bottom: 110, left: 35 }}
							/>
						</div>
					</div>
				</div>
			</>
		)
	};
	return (
		<>
			<CurrentBots />
		</>

	)
};

export default VisitedBots;