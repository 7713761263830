import React from "react";
import "./TopBar.scss"
import { Link, Navigate, useLocation } from "react-router-dom";
import api from "../../veracityapi";
import bApi from "../../API/BeaconAPI";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Adnetworks } from "components/AFP/Constants";
import { store } from "Redux/store";

const TopBar = () => {
	const timeZone = localStorage.getItem('timezone');
	const websiteName = localStorage.getItem("websiteName");
	const [switchAccountToggle, setSwitchAccountToggle] = React.useState(false);
	const [myAccountToggle, setMyAccountToggle] = React.useState(false);
	const [helpToggle, setHelpToggle] = React.useState(false);
	const [time, setTime] = React.useState(moment()?.tz(timeZone)?.format("HH:mm:ss"));
	const date = moment().tz(timeZone)?.format("Do MMM YYYY");
	const [switchWebsite, setSwitchWebsite] = React.useState(false);
	const { pathname } = useLocation();
	const numbersOfWebsites = localStorage.getItem("websitesNumber");
	const admin = localStorage.getItem("admin") === "true";
	const websiteToggleRef = React.useRef(null);
	const myAccountToggleRef = React.useRef(null);
	const helpRef = React.useRef(null);
	const isAFPEnabled = localStorage.getItem("isAFPEnabled") == "true";
	const dispatch = useDispatch();

	React.useEffect(() => {
		let intervalId;
		if (timeZone !== undefined || timeZone !== null) {
			intervalId = setInterval(() => {
				setTime(moment()?.tz(timeZone)?.format('HH:mm:ss'));
			}, 500);
		}
		return () => clearInterval(intervalId);
	}, [timeZone]);


	function handleSwitchAccount() {
		api.setCustomerGUID(null);
		bApi.logout();
		api.setWebsiteGUID(null);
		bApi.setWebsiteId(null);
		setSwitchWebsite(true);
		setSwitchAccountToggle(false);		
	};

	function handleWebsiteToggle() {
		setSwitchAccountToggle(!switchAccountToggle)
	};

	function handleMyAccountToggle() {
		setMyAccountToggle(!myAccountToggle)
	};

	const handleOutsideClick = (event) => {
		if (websiteToggleRef.current && !websiteToggleRef.current.contains(event.target)) {
			setSwitchAccountToggle(false);
		};
		if (myAccountToggleRef.current && !myAccountToggleRef.current.contains(event.target)) {
			setMyAccountToggle(false)
		}
		if (helpRef.current && !helpRef.current.contains(event.target)) {
			setHelpToggle(false)
		}
	};

	React.useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	React.useMemo(() => {
		if (pathname === "/loading") {			
			setSwitchWebsite(!switchWebsite)
		}
	}, [pathname]);

	if (switchWebsite) {
		return (

			<Navigate
				to={{
					pathname: "/select",
				}}
			/>
		)
	};

	if (pathname === "/" || pathname === "/loading" || pathname === "/select") {
		return null
	};

	let timezoneNoUnderscore = timeZone?.replace("_", " ");

	return (
		<div className="topbar">
			<div className="clock">
				<p className="website-name">{websiteName}</p>
				<p className="time">{time} </p>
				<p className="date">{date}</p>
				<p className="timeZone">{timezoneNoUnderscore}</p>
			</div>
			<div className="user-account">

				<Link to='/manage/company' className="links">
					<div className={pathname === '/manage/company' ? "icon-box-active" : "icon-box"}>Company</div>
				</Link>

				{(numbersOfWebsites === "1" && !admin) ?
					<Link to='/manage/website' className="links">
						<div className={pathname === "/manage/website" ? "icon-box-active" : "icon-box"}>Website</div>
					</Link>

					:
					<div ref={websiteToggleRef} className={pathname === "/manage/website" ? "icon-box-active" : "icon-box"} onClick={handleWebsiteToggle}>Website</div>
				}
				{isAFPEnabled ?
					<div ref={helpRef} className={pathname === '/help' ? "icon-box-active" : "icon-box"} onClick={() => setHelpToggle(!helpToggle)}>Help</div> :
					<Link to="/help" className="links">
						<div className={pathname === '/help' ? "icon-box-active" : "icon-box"}>Help</div>
					</Link>
				}

				{helpToggle &&
					<div className="help-toggle">
						<Link to='/help' className="links">
							<div className="switch-toggle-data">FAQ</div>
						</Link>
						<Link to="help/dynamic-links" className="links">
							<div className="switch-toggle-data" >Dynamic Links</div>
						</Link>
						<Link to="help/bot-types" className="links">
							<div className="switch-toggle-data" >Bot Types</div>
						</Link>
					</div>
				}

				<div ref={myAccountToggleRef} className={pathname === '/manage/user' ? "icon-box-active" : "icon-box"} onClick={handleMyAccountToggle}>Settings</div>



				{switchAccountToggle &&
					<>
						<div className="triangle">
							<Link to='/manage/website' className="links">
								<div className="switch-toggle-data">View/Edit</div>
							</Link>
							<div className="switch-toggle-data" onClick={handleSwitchAccount} >Switch Website</div>
						</div>
					</>
				}
				{myAccountToggle &&
					<>
						<div className="me-toggle-triangle">
							<Link to='/manage/user' className="links">
								<div className="switch-toggle-data">View/Edit</div>
							</Link>
							<div className="switch-website" onClick={api.logout}> Sign Out</div>
						</div>
					</>
				}
			</div>
		</div>
	);
};

export default TopBar;
