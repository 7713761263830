import React from "react";
import { ResponsiveLine } from '@nivo/line';
import TrafficMessage from "../../../../../SharedComponents/TrafficMessage/TrafficMessage";
import GraphsToolTip from "../../../../../SharedComponents/GraphsToolTip/GraphsToolTip";
import CustomTooltip from "../../../../../SharedComponents/CustomTooltip/CustomTooltip";
import { MaxIcon } from "../../../../../SVG/MaxIcon";
import GraphModal from "../../../../../SharedComponents/GraphModal/GraphModal";

const TodayReferralLineGraph = (props) => {
	const graphRef = React.useRef(null);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [maxIcon, setMaxIcon] = React.useState(false);
	const handleMaximizeClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const MyResponsiveLineGraph = React.useMemo(() => {
		if (props.data.length > 0) {
			return (

				<ResponsiveLine
					data={props?.data}
					margin={{ top: 10, right: 50, bottom: 75, left: 40 }}
					xScale={{ type: 'point' }}
					yScale={{
						type: 'linear',
						min: 0,
						max: 'auto',
						stacked: false,
						reverse: false
					}}
					enablePoints={false}
					// colors={colours}
					// yFormat="0e"
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 25,
						legendPosition: 'middle',
						legendOffset: 36,
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legendOffset: -40,
						legendPosition: 'middle',
						format: (value) => value % 2=== 0 ? value : ""
					}}
					pointSize={10}
					pointColor={{ theme: 'background' }}
					pointBorderWidth={2}
					pointBorderColor={{ from: 'serieColor' }}
					pointLabelYOffset={-12}
					useMesh={true}
					enableSlices="x"
					sliceTooltip={ (e)=><CustomTooltip data={e} graphRef={graphRef}/>}
				/>
			)
		} else {
			return (
				<TrafficMessage message="Sorry there has been no traffic today." />
			)
		};

	}, [props.data])

	return (
		<>
			<div className="graph-header" onMouseEnter={() => setMaxIcon(true)} onMouseLeave={() => setMaxIcon(false)}>
				<div className="graph-heading">Operating System</div>
				<GraphsToolTip message="This summary shows which operating system bots visiting your website are reporting as using." />
				{maxIcon &&
					<div className="max-icon" onClick={handleMaximizeClick} title="Expand Graph">
						<MaxIcon />
					</div>
				}
			</div>
			<div style={{ height: '100%', width: '100%' }} ref={graphRef} onMouseEnter={() => setMaxIcon(true)} onMouseLeave={() => setMaxIcon(false)}>
				{MyResponsiveLineGraph}
			</div>
			{isModalOpen &&
				<GraphModal
					isOpen={isModalOpen}
					onClose={handleMaximizeClick}
					graph={MyResponsiveLineGraph}
					title="Operating System"
				/>
			}
		</>

	)
};

export default TodayReferralLineGraph;