import { MetaIcon, MicrosoftLogo, GoogleLogo, TikTokLogo, AdWordsLogo, DV360Logo, LinkedInLogo } from "../../SVG/AdNetworksIcons";
const filterPathname = false;
export const Adnetworks = [
    { id: 1, label: 'Google', connectionURL: 'google', name: 'Google Ads', icon: <GoogleLogo fill={filterPathname ? "darkgray" : "white"} height={filterPathname ? "16" : "28"} viewBox={filterPathname ? "0 0 44 46" : "0 0 45 46"} />, backgroundColor: "#EA4335" },
    { id: 2, label: 'Meta', connectionURL: 'facebook', name: 'Facebook Ads', icon: <MetaIcon fill={filterPathname ? "blue" : "white"} />, backgroundColor: "linear-gradient(116.49deg, #0869E1 16.63%, #0377EF 38.7%, #007EF7 58.41%, #0081FA 82.68%)" },
    { id: 3, label: 'Microsoft', connectionURL: 'microsoft', name: 'Microsoft Ads', icon: <MicrosoftLogo fill={filterPathname ? "#db4437" : "white"} />, backgroundColor: " #DB4437" },
    { id: 4, label: 'LinkedIn', connectionURL: 'linkedin', name: 'LinkedIn Ads', icon: <LinkedInLogo fill={filterPathname ? "#db4437" : "white"} height={filterPathname ? "100" : "32"} />, backgroundColor: "#0072b1" },
    { id: 5, label: 'TikTok', connectionURL: 'tiktok', name: 'TikTok Ads', icon: <TikTokLogo fill={filterPathname ? "#db4437" : "white"} height={filterPathname ? "16" : "28"} />, backgroundColor: " #000000" },
    { id: 6, label: 'Google Performance Max', connectionURL: 'google', name: 'Google Performance Max Ads', icon: <AdWordsLogo fill={filterPathname ? "darkgray" : "white"} height={filterPathname ? "100" : "32"} viewBox={filterPathname ? "0 0 34 34" : "0 0 35 35"} />, backgroundColor: " #FFCE44", connectorHidden: true },
    { id: 7, label: 'Display & Video 360', connectionURL: 'dv360', name: 'DV360 Ads', icon: <DV360Logo fill={filterPathname ? "darkgray" : "white"} height={filterPathname ? "100" : "32"} />, backgroundColor: " #0F9D58", connectorHidden: false }
];
export const NO_BASELINE_RUN = 'NO_BASELINE_RUN';

export const botClassificationName = {
    1: "Scraper",
    2: "Crasher",
    3: "Imposter",
    4: "Thief",
    5: "Blocker",
    6: "Poster",
    7: "Clicker",
    8: "Pretender",
    9: "Scout",
    10: "Scanner",
    11: "Ghost"
};

export const botRiskHighToLow = ["Thief", "Imposter", "Scraper", "Crasher", "Clicker", "Scanner", "Blocker", "Scout", "Ghost", "Pretender", "Hoarder", "Poster"];

export const classificationColors = {
    //Dangerous
    "Thief": "#e22937",
    "Imposter": "#e5949b",
    //High Risk
    "Scraper": "#ea6f3e",
    "Crasher": "#f4b79e",
    "Clicker": "#f4b79e",
    //Medium Risk
    "Scanner": "#5c97cc",
    "Blocker": "#82c6eb",
    "Scout": "#e0f1fa",
    //Nuisance
    "Ghost": "#662d91",
    "Pretender": "#8c62ad",
    "Hoarder": "#c5b0d6",
    "Poster": "#f0ebf5",
};
export const riskLevels = {
    0: 'None',
    1: 'Low',
    2: 'Medium',
    3: 'High',
    4: 'Dangerous'
};

export const colors = {
    0: 'grey',
    1: '#662D91',
    2: '#068DD7',
    3: '#EA6F3E',
    4: '#E22937'
};

export const riskLevelsColours = {
    'None': 'grey',
    'Low': '#662D91',
    'Medium': '#068DD7',
    'High': '#EA6F3E',
    'Dangerous': '#E22937'
};

export const botClassificationRisks = new Map([
    [1, 3],
    [2, 3],
    [3, 4],
    [4, 4],
    [5, 2],
    [6, 1],
    [7, 2],
    [8, 1],
    [9, 2],
    [10, 1],
    [11, 1],
]);

export const classicWebsiteGUIDs = [
    //"2c235a08-58ab-4f4f-8f5b-614f2e189c7b", //Busy Bee
    // "8fa0e6b2-ad98-4913-b870-d21b2b5da7f4", //IceLink
    // "139fd23a-5ed2-4327-912c-4bd7470676f0", //QV Skincare
    //"2c235a08-58ab-4f4f-8f5b-614f2e189c7a", //Caspian
    // "d14db85a-0bed-4635-896a-baa332442335", //Hyundai
    // "c42d783a-f08b-49ab-a2f7-4ba0f54f97ab", //National College
    // "ca58e6de-b552-4a21-a693-08d53beb5d11", //Kelling Group
    // "0b01660a-fe14-4154-b9ca-8a3a6f711925", //YLEM
    // "bcc81eb2-df1a-4f83-8864-42888d39f1ac", //Plan Chicly
    // "559ce39e-adca-4542-bd39-cca5ed76b36d", //Hair Loss Treatment Clinics
    // "a74ea1de-104a-4432-86db-0be187bde626" //Evolo

];

export const wtpDisableMessage = `You do not hold an active licence for Web Threat Protection and it is disabled. The data shown below is for traffic from your protected ads only. To enable bot protection for traffic from all sources, please get in touch with your sales representative.`;

export const legendColors = {
    'humans': '#662D91',
    'malicious_bots': '#E22937',
    'good_bots': '#49B65B'
}

export const goodBotsTypesColors = {
    "Apple": "#49B65B",
    "Facebook": "#662D91",
    "Google": "#211728",
    "Seekport (Germany)": "#ea6f3e",
    "ByteSpider": "#f4b79e",
    "Microsoft/Bing":"#c5b0d6",
    "Other": "#068dd7",
    "Semrush": '#E22937', 
    "ManageEngine Site24x7": "#8c62ad"
}
export const riskLevelsPerClassification ={
    //Dangerous
    "Thief": "Dangerous",
    "Imposter": "Dangerous",
    //High Risk
    "Scraper": "High",
    "Crasher": "High",
    "Clicker": "High",
    //Medium Risk
    "Scanner": "Medium",
    "Blocker": "Medium",
    "Scout": "Medium",
    //Nuisance
    "Ghost": "Low",
    "Pretender": "Low",
    "Hoarder": "Low",
    "Poster": "Low",
}
