import React from "react";
import TrafficMessage from "../../../../SharedComponents/TrafficMessage/TrafficMessage";
import { useSelector } from "react-redux";
import Table from "components/Table";

const AffiliateTable = () => {
	const affiliatesData = useSelector(state => state.sessionAffiliates.data);
	const formatData = () => {
		var result = [];
		let id = 0;
		const uniqueAffiliates = [...new Set(Object.keys(affiliatesData.bot).concat(Object.keys(affiliatesData.human)))];

		uniqueAffiliates.forEach(affiliate => {
			const botCount = affiliatesData.bot[affiliate] || 0;
			const humanCount = affiliatesData.human[affiliate] || 0;
			const existing = result.find(obj => obj.affiliate === affiliate);

			if (existing) {
				existing.total_Bots += botCount;
				existing.total_Humans += humanCount;
			} else {
				if (affiliate !== "No Affiliate" && affiliate !== "null") {
					result.push(
						{ id, affiliate, total_Bots: botCount, total_Humans: humanCount, bot_percentage: ((botCount / (botCount + humanCount)) * 100).toFixed(1) }
					);
					id++;
				}
			}
		});

		result = result.sort(({ bot_percentage: a }, { bot_percentage: b }) => b - a);
		return result;
	}
	const headers = [
		{ headerName: "Affiliate", field: 'affiliate' },
		{ headerName: "Total Humans", field: 'total_Humans' },
		{ headerName: "Total Bots", field: 'total_Bots' },
		{ headerName: "Bot Percentage", field: 'bot_percentage' }
	];

	const formattedData = formatData();
	
	return (
		<>
			{formattedData.length !== 0 ?
					<div className="table-container-parent">
						<Table
							headers={headers}
							data={formattedData}
						/>
					</div>
				:
				<div style={{ background: "white", position: "relative", minHeight: "30vh" }}>
					<TrafficMessage message="No affiliate traffic in the time period selected." />
				</div>
			}
		</>
	)
}

export default AffiliateTable;