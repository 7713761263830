import React from "react";
import "./CurrentActivity.scss";
import ActivePages from "./CurrentActivityComponents/ActivePages";
import { useSelector, useDispatch } from "react-redux";
import CurrentActivityPanelData from "./CurrentActivityPanelController";
import GraphsToolTip from "../../../../SharedComponents/GraphsToolTip/GraphsToolTip";
import VisitedBots from "./CurrentActivityComponents/VisitedBots";
import TodayBarGraph from "../TodayFinishedSessionsPanel/TodayFinishedSessionsComponents/TodayBarGraph";
let updatTime = 40000;

const CurrentActivityPanel = () => {
	const dispatch = useDispatch();
	const websiteStatus = useSelector(state => state.websiteDetails.data.websiteDetails.status);
	const botsString = websiteStatus === "protect" ? "Blocked" : "Detected";
	const activeSessionsData = useSelector(state => state.todayCurrentlyActiveSessions.data);
	const finishedSessions = useSelector(state => state.todayFinishedSessions.data);
	const [update, setUpdate] = React.useState(false);
	let activeBotsHeading;

	if (botsString == "Blocked") {
		activeBotsHeading = "blocked bots";
	} else {
		activeBotsHeading = "active bots";
	}

	React.useEffect(() => {
		setTimeout(() => {
			setUpdate(!update)
		}, updatTime)
	}, []);

	React.useEffect(() => {
		CurrentActivityPanelData({}, dispatch);
	}, [update]);

	return (
		<div className="currentActivity-graphs-section">
			<VisitedBots botsString={botsString} activeBots={activeSessionsData.sessionBots} finishedSessions={finishedSessions.todayFinisihedSessions} botsVisited={activeSessionsData.botsVisited} todayBlockedBots={activeSessionsData.blockedTodaysBots} />
			<div className="active-sessions-graphs">
				<div className="graph-header">
					<div className="graph-heading">Landing pages of today’s {activeBotsHeading}</div>
					<GraphsToolTip message="A list of pages where there is current bot activity, and the total number of bots active on that page." />
				</div>
				<ActivePages activePagesData={activeSessionsData.activePages} />
			</div>
			<div className="finishedSessions-barGraph">
				<div className="graph-header">
					<div className="graph-heading">Finished Sessions</div>
					<GraphsToolTip message="Once a session has finished (been inactive for longer than 30 minutes), it will be displayed here." />
				</div>
				<TodayBarGraph finishedSessions={finishedSessions.todayFinisihedSessions} />
			</div>
		</div>
	)
};

export default CurrentActivityPanel;