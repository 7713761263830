import React from "react";
import BotIntentions from "./BotRiskComponents/BotIntentions";
import BotType from "./BotRiskComponents/BotType";
import RiskMeasurement from "./BotRiskComponents/RiskMeasurement";
import "./BotRisk.scss";
import { useSelector } from "react-redux";
import { botClassificationName, classificationColors, riskLevels } from "components/AFP/Constants";
import MyResponsiveRadialBar from "components/RadialBar";
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";

const BotRisk = () => {
	const websiteRiskData = useSelector(state => state.todayBotRiskDetails?.data?.todayWebsiteRiskType);
	const doubleBreakdown = useSelector((state) => state.todayBotRiskDetails?.data?.todayBotClassification);
	const botRiskType = useSelector((state) => state.todayBotRiskDetails.data?.todayBotRiskType)
	const botClassification = useSelector((state) => state.botTypeClassification?.data?.botTypeClassification);
	const websiteStatus = useSelector((state) => state.websiteDetails?.data?.websiteDetails?.status);

	function getTickSize() {
		const values = Object.keys(websiteRiskData?.item).length ?? 0;
		if (values > 20) {
			return 15
		} else {
			return values
		}
	}

	const formatBotTypeData = () => {
		let botTypeTotals = {};
		for (let dates in doubleBreakdown.item) {
			for (let id in doubleBreakdown.item[dates].bot) {
				if (id !== "" && id != 0) {
					if (botTypeTotals[id] === undefined) {
						botTypeTotals[id] = {
							id: parseInt(id),
							label: botClassificationName[id],
							value: doubleBreakdown?.item[dates]?.bot[id],
							color: classificationColors[botClassificationName[id]]
						}
					} else {
						botTypeTotals[id].value += doubleBreakdown?.item[dates]?.bot[id];
					}
				}

			}
		};
		return Object.values(botTypeTotals).sort(function (a, b) {
			return b.value - a.value;
		});
	};

	const formattedBotTypeData = formatBotTypeData();

	const formatDataForRadialGraph = () => {
		const result = [{ id: "Low", data: [] }, { id: "Medium", data: [] }, { id: "High", data: [] }, { id: "Dangerous", data: [] }];

		for (let keys in formattedBotTypeData) {
			for (let data in botClassification.items) {
				for (let id in result) {
					if (botClassification.items[data].bot_classification_id === formattedBotTypeData[keys].id) {
						if (result[id]?.id === botClassification.items[data].risk_level) {
							result[id].data.push({
								x: `${botClassification.items[data].classification} (${botClassification.items[data].risk_level})`,
								y: formattedBotTypeData[keys].value,
								color: classificationColors[botClassification.items[data].classification]
							})
						}
					}
				}
			}
		};
		return result
	};
	const radialBarData = formatDataForRadialGraph();
	return (
		<div className="bot-risk-section">
			<div className="bot-threat-container ">
				<BotIntentions
					botRiskType={botRiskType}
					botClassification={botClassification}
					formattedBotTypeData={formattedBotTypeData}
					title={websiteStatus === "protect" ? "Today's Mitigated Threat Breakdown" : "Threat Breakdown"}
				/>
			</div>
			<div className="blocked-threat-level">
				<section className="header-section">
					<div className="graph-heading">Today’s Blocked Bot Types</div>
					<GraphsToolTip message='Bots data those are blocked' />
				</section>
				<MyResponsiveRadialBar
					data={radialBarData}
					legendClassName='radial-legend-section'
					legendData={formattedBotTypeData}
				/>

			</div>
			<div>

			</div>


		</div>
	)
};

export default BotRisk;