import React from "react";
import "./Websites.css";
import { useSelector, useDispatch } from "react-redux";
import { detectModes } from "../../../Redux/actions"
import TrafficMessage from "../../../SharedComponents/TrafficMessage/TrafficMessage";
import Pagination from "../../../SharedComponents/Pagination/Pagination";
import AddWebsite from "./WebsitesComponents/AddWebsite";
import RemoveWebsite from "./WebsitesComponents/RemoveWebsite";
import EditUser from "../Users/UsersComponents/EditUser";
import Permission from "../../../SharedComponents/PermissionsMessage/Permissions";
import api from "veracityapi";
import bApi from "API/BeaconAPI";
import { Navigate } from "react-router-dom";

const Websites = () => {
	const dispatch = useDispatch();
	const primaryUser = localStorage.getItem("primaryUser");
	const customerWebsites = useSelector((state) => state.websitesList.data);
	const customerPackages = useSelector(state => state.websiteDetails.data.customerPackages);
	const currentPackage = customerPackages.find((val) => val?.current === 1);
	const [addWebsite, setAddWebsite] = React.useState(false);
	const [removeWebsite, setRemoveWebsite] = React.useState(false);
	const [backToSelect, setBackToSelect] = React.useState(false);
	const [currentPage, setCurrentPage] = React.useState(1);
	const itemsPerPage = 5;
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const currentElements = customerWebsites?.items?.slice(startIndex, endIndex) ?? [];
	const [permissionMessage, showPermissionMessage] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const websiteGUID = localStorage.getItem("selected_site");

	const checkInviteUserButtonDisability = (add) => {
		if (primaryUser === "true") {
			if (add && currentElements.length < currentPackage.distinct_urls) return false;
			if (!add && currentElements.length > 1) return false;
		};
		return true;
	};


	const disableAdd = checkInviteUserButtonDisability(true);
	const disableRemove = checkInviteUserButtonDisability(false);

	const handleNonPrimaryUsers = (Message) => {
		if (primaryUser === false) {
			showPermissionMessage(Message)
			setTimeout(() => {
				showPermissionMessage(false)
			}, 10000)
		};

	};

	const changeWebsite = (props) => {
		api.setWebsiteGUID(props.guid);
		bApi.logout();
		localStorage.setItem("websiteName", props.website_name)
		localStorage.setItem("timezone", props?.timezone ?? "Europe/London");
		dispatch(detectModes({
			wtp_status: props?.status,
			afp_status: props?.afp_status,
		}))
		setLoading(true);
	};

	if (loading) {
		return (
			<Navigate to="/loading" />
		);
	};
	if(backToSelect){
		bApi.logout();
		api.setWebsiteGUID(null);
		bApi.setWebsiteId(null);
			return (
					<Navigate to="/select" />
				);
	}
	return (
		<>
			<div className="websites">
				<div className="invite-users">
					<h3>Your Websites</h3>
					<button className={disableAdd ? "edit-button-disable" : "edit-button"} onMouseEnter={() => handleNonPrimaryUsers("You must be a primary user and be below the website limit of your package.")} onClick={() => { disableAdd ? setAddWebsite(false) : setAddWebsite(true) }}>Add Website</button>
				</div>
				{
					customerWebsites.items.length !== 0 ?
						<table className="website-table">
							<thead>
								<tr>
									<th>Website Name</th>
									{/* <th></th> */}
									<th>Website URL</th>
									<th>{`Status`}
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{currentElements.map((website, index) => (
									<tr
										className="user-row"
										key={website.guid}
									>
										<td>{website.website_name}</td>
										{/* <td></td> */}
										<td><a href={`//${website.primary_hostname}`} rel="noreferrer noopener" target="_blank">{website.primary_hostname}</a></td>
										<td>{`WTP: ${website.status ?? "Unknown"}\nAFP: ${website.afp_status ?? "Unknown"}`}</td>
										<td>{website.guid !== websiteGUID && <button className="websites-view-btn" onClick={() => changeWebsite(website)}>View</button>}</td>
										<td><button className={disableRemove ? "remove-button-disable" : "remove-button"} onMouseEnter={() => handleNonPrimaryUsers("You must be a primary user and have at least 1 website.")} onClick={() => { disableRemove ? setRemoveWebsite(false) : setRemoveWebsite(website) }}>Remove</button></td>

									</tr>
								))}
							</tbody>
						</table>
						:
						<TrafficMessage message="There are no users for this website." />
				}
				{customerWebsites.items.length > itemsPerPage && <Pagination paging={customerWebsites.paging } currentPage={currentPage} setCurrentPage={setCurrentPage} pageLimit={itemsPerPage} />}
				{addWebsite && <AddWebsite addWebsites={addWebsite} setAddWebsite={setAddWebsite} />}
				{removeWebsite && <RemoveWebsite setBackToSelect={setBackToSelect} removeWebsite={removeWebsite} setRemoveWebsite={setRemoveWebsite} />}
				{permissionMessage && <Permission permissionMessage={permissionMessage} />}
			</div>
		</>
	);
};

export default Websites;